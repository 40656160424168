import React from 'react';
import obstruction from 'obstruction';
import { connect } from 'react-redux';
import { Form, Icon, Input, Button } from 'antd';
import { newCheckinRequest } from '../../actions/checkinActions';

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const dateFormat = 'YYYY-MM-DD';

class CheckInForm extends React.Component {
  componentDidMount() {
    // To initially disable the submit button
    this.props.form.validateFields();
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.newCheckinRequest({
          ...values,
          workDate: this.props.workDate.format(dateFormat),
        });
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

    // Only show error after a field is touched.
    const hoursError = isFieldTouched('hours') && getFieldError('hours');
    const tagError = isFieldTouched('tag') && getFieldError('tag');
    const activityError = isFieldTouched('activity') && getFieldError('activity');
    return (
      <Form layout="inline" onSubmit={this.handleSubmit}>
        <p>Add a check in:</p>
        <Form.Item validateStatus={hoursError ? 'error' : ''} help={hoursError || ''}>
          {getFieldDecorator('hours', {
            rules: [{ required: true, message: 'Please input the hours spent!' }],
          })(
            <Input
              prefix={<Icon type="hourglass" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Hours"
            />,
          )}
        </Form.Item>
        <Form.Item validateStatus={tagError ? 'error' : ''} help={tagError || ''}>
          {getFieldDecorator('tag', {
            rules: [{ required: true, message: 'Please tag this check in!' }],
          })(
            <Input
              prefix={<Icon type="tag" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Tag"
            />,
          )}
        </Form.Item>
        <Form.Item validateStatus={activityError ? 'error' : ''} help={activityError || ''}>
          {getFieldDecorator('activity', {
            rules: [{ required: true, message: 'Please input what you did!' }],
          })(
            <Input
              prefix={<Icon type="edit" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Activity"
            />,
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const mapStateToProps = obstruction({
  workDate: 'checkin.workDate',
});

const mapDispatchToProps = {
  newCheckinRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'checkin_form' })(CheckInForm));
