import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CheckInForm from '../CheckInForm';
import CheckinsList from '../CheckinsList';
import DailyHoursGraph from '../DailyHoursGraph';
import Greeting from '../Greeting';
import LogoutButton from '../LogoutButton';
import LoginForm from '../LoginForm';
import WorkDate from '../WorkDate';

const Dashboard = props => (
  <Fragment>
    <h1>Time tracker</h1>
    {props.isAuthenticated ? (
      <Fragment>
        <Greeting />
        <WorkDate />
        <CheckInForm />
        <CheckinsList />
        <DailyHoursGraph />
        <LogoutButton />
      </Fragment>
    ) : (
      <LoginForm />
    )}
  </Fragment>
);

Dashboard.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { auth: { isAuthenticated } } = state;

  return {
    isAuthenticated,
  };
};

export default connect(mapStateToProps)(Dashboard);
