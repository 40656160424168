import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import obstruction from 'obstruction';
import { connect } from 'react-redux';
import { Button, DatePicker } from 'antd';
import { setWorkDate, getCheckinsRequest } from '../../actions/checkinActions';

const dateFormat = 'YYYY-MM-DD';

class WorkDate extends Component {
  constructor(props) {
    super(props);

    this.onDateChange = this.onDateChange.bind(this);
    this.nextDay = this.nextDay.bind(this);
    this.prevDay = this.prevDay.bind(this);
  }

  onDateChange(date) {
    if (this.props.isLoading) return;
    this.props.getCheckinsRequest(moment(date).format(dateFormat));
    this.props.setWorkDate(date);
  }

  nextDay() {
    if (this.props.isLoading) return;
    this.props.getCheckinsRequest(moment(this.props.workDate).add(1, 'day').format(dateFormat));
    this.props.setWorkDate(moment(this.props.workDate).add(1, 'day'));
  }

  prevDay() {
    if (this.props.isLoading) return;
    this.props.getCheckinsRequest(moment(this.props.workDate).add(-1, 'day').format(dateFormat));
    this.props.setWorkDate(moment(this.props.workDate).add(-1, 'day'));
  }

  render() {
    return (
      <Fragment>
        <p>Select work date:</p>
        <div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'center' }}>
          <Button icon="caret-left" style={{ marginRight: '8px' }} onClick={this.prevDay} />
          <DatePicker
            onChange={this.onDateChange}
            style={{ marginRight: '8px' }}
            value={this.props.workDate}
            allowClear={false}
          />
          <Button icon="caret-right" onClick={this.nextDay} />
        </div>
      </Fragment>
    );
  }
}

WorkDate.propTypes = {
  setWorkDate: PropTypes.func.isRequired,
  getCheckinsRequest: PropTypes.func.isRequired,
  workDate: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = obstruction({
  workDate: 'checkin.workDate',
  isLoading: 'checkin.isLoading',
});

const mapDispatchToProps = {
  setWorkDate,
  getCheckinsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkDate);
