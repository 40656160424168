import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';

import {
  NEW_CHECKIN_REQUEST,
  GET_CHECKINS_REQUEST,
  DELETE_CHECKIN_REQUEST,
} from '../actions/actionTypes';
import { newCheckinSuccess, getCheckinsSuccess, deleteCheckinSuccess } from '../actions/checkinActions';
import { appAlertSuccess, appAlertError } from '../actions/alertActions';
import { newCheckinService, getCheckinsService, deleteCheckinService } from '../services/checkins';

export function* newCheckinFlow({ hours, tag, activity, workDate }) {
  try {
    yield put(appAlertSuccess('Saving...'))
    const response = yield call(newCheckinService, hours, tag, activity, workDate);
    yield put(newCheckinSuccess(response.data));
    yield put(appAlertSuccess('New check in saved!'))
  } catch (error) {
    const { message } = error.response.data;
    yield put(appAlertError(message, error));
  }
}

export function* getCheckinsFlow({ workDate }) {
  try {
    const response = yield call(getCheckinsService, workDate);
    yield put(getCheckinsSuccess(response.data));
  } catch (error) {
    // TODO: Don't attempt to fetch after logout
    // const { message } = error.response.data;
    // yield put(appAlertError(message, error));
  }
}

export function* deleteCheckinFlow({ checkinId }) {
  try {
    yield put(appAlertSuccess('Deleting...'));
    yield call(deleteCheckinService, checkinId);
    yield put(deleteCheckinSuccess(checkinId));
    yield put(appAlertSuccess('Check in deleted.'));
  } catch (error) {
    const { message } = error.response.data;
    yield put(appAlertError(message, error));
  }
}

export function* watchCheckinFlow() {
  yield all([
    takeLatest(NEW_CHECKIN_REQUEST, newCheckinFlow),
    takeLatest(GET_CHECKINS_REQUEST, getCheckinsFlow),
    takeLatest(DELETE_CHECKIN_REQUEST, deleteCheckinFlow),
  ]);
}

const checkinSagas = [watchCheckinFlow()];

export default checkinSagas;
