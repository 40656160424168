import {
  NEW_CHECKIN_REQUEST,
  NEW_CHECKIN_SUCCESS,
  GET_CHECKINS_REQUEST,
  GET_CHECKINS_SUCCESS,
  DELETE_CHECKIN_REQUEST,
  DELETE_CHECKIN_SUCCESS,
  SET_WORK_DATE,
} from './actionTypes';

export function newCheckinRequest(data) {
  return { type: NEW_CHECKIN_REQUEST, ...data };
}

export function newCheckinSuccess(checkin) {
  return { type: NEW_CHECKIN_SUCCESS, checkin };
}

export function getCheckinsRequest(workDate) {
  return { type: GET_CHECKINS_REQUEST, workDate };
}

export function getCheckinsSuccess(checkins) {
  return { type: GET_CHECKINS_SUCCESS, checkins };
}

export function deleteCheckinRequest(checkinId) {
  return { type: DELETE_CHECKIN_REQUEST, checkinId };
}

export function deleteCheckinSuccess(checkinId) {
  return { type: DELETE_CHECKIN_SUCCESS, checkinId };
}

export function setWorkDate(workDate) {
  return { type: SET_WORK_DATE, workDate }
}
