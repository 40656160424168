import api from '../helpers/apiClient';

export function newCheckinService(hours, tag, activity, workDate) {
  return api.post('/checkins/', { hours, tag, activity, work_date: workDate });
}

export function getCheckinsService(workDate) {
  return api.get('/checkins', { params: { work_date: workDate } });
}

export function deleteCheckinService(checkinId) {
  return api.delete(`/checkins/${checkinId}`);
}
