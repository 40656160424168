import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import obstruction from 'obstruction';
import { connect } from 'react-redux';
import { Button, Empty, Table, Tag } from 'antd';
import { getCheckinsRequest, deleteCheckinRequest } from '../../actions/checkinActions';

const dateFormat = 'YYYY-MM-DD';

class CheckinsList extends Component {
  componentWillMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    this.props.getCheckinsRequest(moment(this.props.workDate).format(dateFormat));
  }

  render() {
    const columns = [
      {
        title: 'Hours',
        dataIndex: 'hours',
        key: 'hours',
      }, {
        title: 'Tag',
        dataIndex: 'tag',
        key: 'tag',
        render: (text, record) => (
          <Tag>{record.tag}</Tag>
        ),
      }, {
        title: 'Activity',
        dataIndex: 'activity',
        key: 'activity',
      }, {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <Button
            style={{ padding: 0, border: 'none' }}
            type="link"
            onClick={() => this.props.deleteCheckinRequest(record.id)}
          >
            Delete
          </Button>
        ),
      },
    ];

    const startDate = moment(this.props.workDate).format(dateFormat);
    return (
      <Fragment>
        <p style={{ marginTop: '1em' }}>{`Check-ins for ${moment(this.props.workDate).format('MMM Do')}:`}</p>
        <Table
          style={{ marginTop: '20px' }}
          columns={columns}
          dataSource={this.props.checkins.filter(x => moment(x.workDate).format(dateFormat) === startDate)}
          rowKey="id"
          locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'You have no check-ins yet! Add one now!'} /> }}
          pagination={false}
          size="small"
        />
      </Fragment>
    );
  }
}

CheckinsList.propTypes = {
  checkins: PropTypes.array.isRequired,
  getCheckinsRequest: PropTypes.func.isRequired,
  deleteCheckinRequest: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = obstruction({
  checkins: 'checkin.checkins',
  workDate: 'checkin.workDate',
  isAuthenticated: 'auth.isAuthenticated',
});

const mapDispatchToProps = {
  getCheckinsRequest,
  deleteCheckinRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckinsList);
